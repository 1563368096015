export const FormatsTypeEnum = {
  DOCUMENT: 'Document',
  IMAGE: 'Image',
  VIDEO: 'Video',
  AUDIO: 'Audio',
  COMPRESSED: 'Compressed',
  EBOOK: 'e-Book',
  CAD: 'CAD',
};

export const FormatsColorEnum = {
  DOCUMENT: '#63E3FF',
  IMAGE: '#B8F389',
  VIDEO: '#F97FEF',
  AUDIO: '#FF9388',
  COMPRESSED: '#5C9DFF',
  EBOOK: '#FFDA7C',
  CAD: '#9E7BFF',
};

export const FORMATS_SCREEN_DATA = [
  {
    type: FormatsTypeEnum.DOCUMENT,
    color: FormatsColorEnum.DOCUMENT,
    formats: [
      {
        name: 'csv',
        text: 'Comma Separated Values',
      },
      {
        name: 'djvu',
        text: 'DjVu File',
      },
      {
        name: 'doc',
        text: 'Microsoft Word Document',
      },
      {
        name: 'docm',
        text: 'Microsoft Word XML Format Macro Enabled Document',
      },
      {
        name: 'docx',
        text: 'Microsoft Word XML Format Document',
      },
      {
        name: 'eml',
        text: 'E-mail Message format',
      },
      {
        name: 'eps',
        text: 'Encapsulated PostScript',
      },
      {
        name: 'md',
        text: 'Markdown plain text markup language',
      },
      {
        name: 'msg',
        text: 'Outlook E-mail Message',
      },
      {
        name: 'odp',
        text: 'OpenDocument Presentation',
      },
      {
        name: 'ods',
        text: 'OpenDocument Spreadsheet',
      },
      {
        name: 'odt',
        text: 'OpenDocument Text',
      },
      {
        name: 'pdf',
        text: 'Portable Document Format',
      },
      {
        name: 'pps',
        text: 'Microsoft PowerPoint Presentation',
      },
      {
        name: 'ppsx',
        text: 'Microsoft PowerPoint 2007 Slide Show',
      },
      {
        name: 'ppt',
        text: 'Microsoft PowerPoint Presentation',
      },
      {
        name: 'pptm',
        text: 'Microsoft PowerPoint XML Format Macro Enabled Presentation',
      },
      {
        name: 'pptx',
        text: 'Microsoft PowerPoint 2007 Presentation',
      },
      {
        name: 'ps',
        text: 'PostScript',
      },
      {
        name: 'pub',
        text: 'Microsoft Publisher',
      },
      {
        name: 'rtf',
        text: 'Rich Text Format',
      },
      {
        name: 'tex',
        text: 'LaTeX typesetting source file',
      },
      {
        name: 'txt',
        text: 'Text Document',
      },
      {
        name: 'wks',
        text: 'Microsoft Works Spreadsheet',
      },
      {
        name: 'wpd',
        text: 'WordPerfect Document',
      },
      {
        name: 'wps',
        text: 'Microsoft Works Document',
      },
      {
        name: 'xlr',
        text: 'Works Spreadsheet or Chart (Microsoft Corporation)',
      },
      {
        name: 'xls',
        text: 'Microsoft Excel Spreadsheet',
      },
      {
        name: 'xlsm',
        text: 'Microsoft Excel XML Format Macro Enabled Spreadsheet',
      },
      {
        name: 'xlsx',
        text: 'Microsoft Excel 2007 Spreadsheet',
      },
      {
        name: 'xps',
        text: 'Open XML Paper Specification',
      },
    ],
  },
  {
    type: FormatsTypeEnum.COMPRESSED,
    color: FormatsColorEnum.COMPRESSED,
    formats: [
      {
        name: '7z',
        text: '7-Zip Compressed File',
      },
      {
        name: 'cab',
        text: 'Cabinet File',
      },
      {
        name: 'lzh',
        text: 'Compressed Archive File',
      },
      {
        name: 'rar',
        text: 'WinRAR Compressed Archive',
      },
      {
        name: 'tar',
        text: 'Tape Archive File',
      },
      {
        name: 'tar.bz2',
        text: 'Bzip 2 UNIX Compressed File',
      },
      {
        name: 'tar.gz',
        text: 'Gzip Compressed Archive',
      },
      {
        name: 'yz1',
        text: 'DeepFreezer Compressed Archive',
      },
      {
        name: 'zip',
        text: 'Compressed Archive File',
      },
    ],
  },

  {
    type: FormatsTypeEnum.IMAGE,
    color: FormatsColorEnum.IMAGE,
    formats: [
      {
        name: 'ai',
        text: 'Adobe Illustrator Artwork',
      },
      {
        name: 'avif',
        text: 'AV1 Image format',
      },
      {
        name: 'bmp',
        text: 'Windows bitmap',
      },
      {
        name: 'cdr',
        text: 'Corel Draw Vector drawing file',
      },
      {
        name: 'emf',
        text: 'Windows Enhanced Metafile',
      },
      {
        name: 'gif',
        text: 'Compuserve graphics interchange',
      },
      {
        name: 'heic',
        text: 'High Efficiency Image File Format',
      },
      {
        name: 'jfif',
        text: 'JPEG File Interchange Format',
      },
      {
        name: 'jpg',
        text: 'JPEG compliant image',
      },
      {
        name: 'odg',
        text: 'OpenDocument Drawing',
      },
      {
        name: 'pcx',
        text: 'Paintbrush Bitmap Image',
      },
      {
        name: 'png',
        text: 'Portable Network Graphic',
      },
      {
        name: 'psd',
        text: 'Photoshop Document',
      },
      {
        name: 'svg',
        text: 'Scalable Vector Graphics',
      },
      {
        name: 'tga',
        text: 'Truevision Targa Graphic',
      },
      {
        name: 'tiff',
        text: 'Tagged image file format',
      },
      {
        name: 'wbmp',
        text: 'Wireless Bitmap File Format',
      },
      {
        name: 'webp',
        text: 'Lossily compressed image file',
      },
      {
        name: 'wmf',
        text: 'Windows Metafile',
      },
    ],
  },
  {
    type: FormatsTypeEnum.AUDIO,
    color: FormatsColorEnum.AUDIO,
    formats: [
      {
        name: '3ga',
        text: '3GA Multimedia File',
      },
      {
        name: 'aac',
        text: 'Advanced Audio Coding File',
      },
      {
        name: 'ac3',
        text: 'AC3 Audio File',
      },
      {
        name: 'aiff',
        text: 'Audio Interchange File Format',
      },
      {
        name: 'flac',
        text: 'Free Lossless Audio Codec',
      },
      {
        name: 'm4a',
        text: 'Compressed video file',
      },
      {
        name: 'm4r',
        text: 'iPhone Ringtone File',
      },
      {
        name: 'midi',
        text: 'Musical Instrument Digital Interface',
      },
      {
        name: 'mp3',
        text: 'Compressed audio file',
      },
      {
        name: 'ogg',
        text: 'Ogg Vorbis Compressed Audio File',
      },
      {
        name: 'ra',
        text: 'RealMedia Streaming Media',
      },
      {
        name: 'ram',
        text: 'RealMedia Metafile',
      },
      {
        name: 'wav',
        text: 'Windows audio file',
      },
      {
        name: 'wma',
        text: 'Windows Media Audio',
      },
    ],
  },
  {
    type: FormatsTypeEnum.CAD,
    color: FormatsColorEnum.CAD,
    formats: [
      {
        name: 'dwg',
        text: 'AutoCAD Drawing Database',
      },
      {
        name: 'dxf',
        text: 'AutoCAD Drawing Interchange Format',
      },
    ],
  },
  {
    type: FormatsTypeEnum.VIDEO,
    color: FormatsColorEnum.VIDEO,
    formats: [
      {
        name: '3g2',
        text: '3GPP2 Multimedia File',
      },
      {
        name: '3gp',
        text: '3GPP Multimedia File',
      },
      {
        name: '3gpp',
        text: '3GPP Multimedia File',
      },
      {
        name: 'asf',
        text: 'Advanced Streaming Format',
      },
      {
        name: 'avi',
        text: 'Windows video file',
      },
      {
        name: 'flv',
        text: 'Flash video',
      },
      {
        name: 'gvi',
        text: 'Google video file',
      },
      {
        name: 'm4v',
        text: 'MPEG-4 Video File',
      },
      {
        name: 'mkv',
        text: 'Matroska Multimedia Container',
      },
      {
        name: 'mod',
        text: 'Hard disk camera movie',
      },
      {
        name: 'mov',
        text: 'Apple QuickTime Movie',
      },
      {
        name: 'mp4',
        text: 'MPEG-4 Video File',
      },
      {
        name: 'mpg',
        text: 'Moving Picture Experts Group File',
      },
      {
        name: 'mts',
        text: 'AVCHD Video Fil',
      },
      {
        name: 'rm',
        text: 'RealMedia Streaming Media',
      },
      {
        name: 'rmvb',
        text: 'RealVideo Variable Bit Rate File',
      },
      {
        name: 'ts',
        text: 'Video Transport Stream File',
      },
      {
        name: 'vob',
        text: 'Video Object File',
      },
      {
        name: 'webm',
        text: 'Multimedia Container Format',
      },
      {
        name: 'wmv',
        text: 'Windows Media Video',
      },
    ],
  },
  {
    type: FormatsTypeEnum.EBOOK,
    color: FormatsColorEnum.EBOOK,
    formats: [
      {
        name: 'azw',
        text: 'Amazon Kindle eBook File',
      },
      {
        name: 'azw3',
        text: 'Amazon KF8 eBook File',
      },
      {
        name: 'cbc',
        text: 'eBook Format',
      },
      {
        name: 'cbr',
        text: 'Comic Book RAR Archive',
      },
      {
        name: 'cbz',
        text: 'Comic Book Archive File',
      },
      {
        name: 'chm',
        text: 'Compiled HTML Help File',
      },
      {
        name: 'epub',
        text: 'Open eBook File',
      },
      {
        name: 'fb2',
        text: 'FictionBook 2.0 File',
      },
      {
        name: 'lit',
        text: 'Microsoft eBook File',
      },
      {
        name: 'lrf',
        text: 'Sony Portable Reader File',
      },
      {
        name: 'mobi',
        text: 'Mobipocket eBook',
      },
      {
        name: 'pdb',
        text: 'Palm Media eBook File',
      },
      {
        name: 'pml',
        text: 'eBook File',
      },
      {
        name: 'prc',
        text: 'Mobipocket eBook File',
      },
      {
        name: 'rb',
        text: 'RocketEdition eBook File',
      },
      {
        name: 'tcr',
        text: 'Psion eBook File',
      },
    ],
  },
];
