import styled, { css } from 'styled-components';
import { GradientSuperHeading, LinkWithArrow } from '~/src/components/Typography';
import { Button } from '@uc-common/button';
import { Link } from '~/src/components/Link';

export const ConversionFeaturesScreenGradientSuperHeading = styled(GradientSuperHeading)`
  align-self: center;
  display: block;

  background: radial-gradient(
    141.01% 172.5% at 0% 0%,
    rgb(73, 36, 177) 0%,
    rgb(92, 161, 255) 84.45%
  );
`;

export const ConversionFeaturesScreenFeaturesGrid = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    display: grid;
    grid-gap: 20px;
    margin-top: 50px;

    ${tablet()} {
      grid-template-columns: 1fr 1fr;
      margin-top: 70px;
    }

    ${laptop()} {
      grid-template-columns: repeat(3, 1fr);
    }
  `
);

export const ConversionFeaturesScreenButtonWithArrow = styled(Button)(
  ({ theme: { tablet, laptop } }) => css`
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    max-width: 300px;
    margin: 30px auto 0;

    ${tablet()} {
      font-size: 17px;
      margin-top: 40px;
    }

    ${laptop()} {
      font-size: 20px;
      margin-top: 50px;
      max-width: calc(33% - 20px);
    }

    ${LinkWithArrow} {
      color: #ffffff;
    }

    &:hover {
      ${LinkWithArrow} {
        &:after {
          transform: translateX(0.15em);
        }
      }
    }
  `
);

export const ConversionFeaturesScreenInlineLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid;
  border-color: transparent;
  transition: color 0.1s ease-in;
  border-color: #596570;

  &:hover {
    color: #ffffff;
  }
`;
