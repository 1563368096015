import React, { useState } from 'react';

import { Container } from '@uc-common/container';
import { BuzzLogoIdEnum, logos } from '@uc-common/buzz-logo';
import { ButtonTypeEnum } from '@uc-common/button';

import { Link } from '~/src/components/Link';
import { Quote } from '~/src/components/forSolutions';
import {
  FeaturesScreenIntegrationSection,
  FeaturesScreenIntegrationContainer,
  FeaturesScreenIntegrationTitle,
  FeaturesScreenIntegrationParagraph,
  FeaturesScreenIntegrationLogo,
  FeaturesScreenArrowIcon,
  FeaturesScreenArrowButton,
  FeaturesScreenArrowButtonText,
} from '~/src/components/forELearning/FeaturesScreen/styles/FeaturesScreen.styles';
import { Section } from '~/src/components/forMain/Section';
import {
  FormatsScreenSubHeading,
  FormatsScreenButtonsContainer,
  FormatsScreenCTAButton,
  FormatsScreenButton,
  FormatsScreenAvailableFormatsContainer,
  FormatsScreenFormatsGrid,
  FormatsScreenFormatSectionTitle,
  FormatsScreenFormatSectionList,
  FormatsScreenFormatSectionListItem,
  FormatsScreenFormatSectionListItemName,
  FormatsScreenFormatSectionListItemDescription,
  FormatsScreenGradientOverlay,
} from './styles/FormatsScreen.styles';
import { FORMATS_SCREEN_DATA } from './data';

export const FormatsScreen = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Section data-navbar-theme="dark">
      <Container>
        <FormatsScreenSubHeading>
          Popular document, media and file formats supported
        </FormatsScreenSubHeading>

        <FormatsScreenButtonsContainer>
          <FormatsScreenCTAButton
            forwardedAs={Link}
            to="/accounts/signup/"
            type={ButtonTypeEnum.PRIMARY}
            data-analytics="btn_signup_1"
          >
            Start building for free
          </FormatsScreenCTAButton>
        </FormatsScreenButtonsContainer>

        <FormatsScreenAvailableFormatsContainer $isExpanded={isExpanded}>
          <FormatsScreenFormatsGrid>
            {FORMATS_SCREEN_DATA.map(({ type, color, formats = [] }) => (
              <div>
                <FormatsScreenFormatSectionTitle>{type}</FormatsScreenFormatSectionTitle>
                <FormatsScreenFormatSectionList>
                  {formats.map(({ name, text }) => (
                    <FormatsScreenFormatSectionListItem>
                      <FormatsScreenFormatSectionListItemName $color={color ?? '#ECECEC'}>
                        {name}
                      </FormatsScreenFormatSectionListItemName>
                      <FormatsScreenFormatSectionListItemDescription>
                        {text}
                      </FormatsScreenFormatSectionListItemDescription>
                    </FormatsScreenFormatSectionListItem>
                  ))}
                </FormatsScreenFormatSectionList>
              </div>
            ))}
          </FormatsScreenFormatsGrid>
          {!isExpanded && <FormatsScreenGradientOverlay />}
        </FormatsScreenAvailableFormatsContainer>

        <FormatsScreenButtonsContainer>
          <FormatsScreenButton
            type={ButtonTypeEnum.TRANSPARENT}
            ghost
            onClick={() => setIsExpanded((prevState) => !prevState)}
          >
            {isExpanded ? 'Hide' : 'Open'}
          </FormatsScreenButton>
        </FormatsScreenButtonsContainer>
      </Container>

      <FeaturesScreenIntegrationSection>
        <FeaturesScreenIntegrationContainer>
          <FeaturesScreenIntegrationLogo
            src={logos[BuzzLogoIdEnum.POLISHED].src}
            alt="Polished logo"
          />
          <FeaturesScreenIntegrationTitle>
            Résumé documents no-coded in a day{' '}
          </FeaturesScreenIntegrationTitle>
          <FeaturesScreenIntegrationParagraph>
            On Polished, job seekers upload their résumés to be reviewed by experts via video. In
            just one day, the founder integrated Uploadcare with Webflow and Zapier and solved both
            parts of the marketplace equation: handling the documents, and handling the videos.
          </FeaturesScreenIntegrationParagraph>
          <FeaturesScreenArrowButton to="/customers/polished/" data-analytics="btn_polished">
            <FeaturesScreenArrowButtonText>Read more</FeaturesScreenArrowButtonText>
            <FeaturesScreenArrowIcon />
          </FeaturesScreenArrowButton>
        </FeaturesScreenIntegrationContainer>
      </FeaturesScreenIntegrationSection>

      <Quote
        logoSrc={logos[BuzzLogoIdEnum.POLISHED].src}
        logoAlt="Polished logo"
        quoteText="If I wanted to get more technical with Uploadcare, it's very customisable through the API. It was really a sweet spot
       between being no-code friendly but also it didn't seem like it had any limitations."
        avatarSrc="https://ucarecdn.com/1f50d366-0a16-48bc-985c-aea4b6b95745/-/scale_crop/88x88/center/-/format/auto/-/quality/normal/"
        avatarAlt="Will Smith | Founder"
      />
    </Section>
  );
};
