import React from 'react';

import { Container } from '@uc-common/container';
import { ButtonTypeEnum } from '@uc-common/button';
import { LinkWithArrow } from '~/src/components/Typography';
import { Section } from '~/src/components/forMain/Section';
import { FeatureCard } from '~/src/components/forSolutions';
import { SolutionsSubHeading } from '~/src/components/forSolutions/styled-components';
import { Link } from '~/src/components/Link';
import {
  ConversionFeaturesScreenGradientSuperHeading,
  ConversionFeaturesScreenFeaturesGrid,
  ConversionFeaturesScreenButtonWithArrow,
} from './styles/ConversionFeaturesScreen.styles';
import { data } from './data';

export const ConversionFeaturesScreen = () => (
  <Section data-navbar-theme="dark">
    <Container>
      <ConversionFeaturesScreenGradientSuperHeading>
        File conversion features
      </ConversionFeaturesScreenGradientSuperHeading>
      <SolutionsSubHeading>All you need in a document conversion software</SolutionsSubHeading>

      <ConversionFeaturesScreenFeaturesGrid>
        {data.map(({ title, text }) => (
          <FeatureCard key={title} title={title} text={text} />
        ))}
      </ConversionFeaturesScreenFeaturesGrid>

      <ConversionFeaturesScreenButtonWithArrow
        forwardedAs={Link}
        to="/features/"
        type={ButtonTypeEnum.PRIMARY}
        data-analytics="btn_conversion-features-screen_explore-features"
      >
        <LinkWithArrow>Explore all features</LinkWithArrow>
      </ConversionFeaturesScreenButtonWithArrow>
    </Container>
  </Section>
);
