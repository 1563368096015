import React from 'react';

import { ButtonTypeEnum, ButtonSizeEnum } from '@uc-common/button';
import { Container } from '@uc-common/container';

import { Section } from '~/src/components/forMain/Section';
import { SolutionsSubHeading } from '~/src/components/forSolutions/styled-components';
import {
  FeaturesButton,
  FeaturesSubtitle,
  ScreenDescriptionWrapper,
  ScreenInfoDescription,
  ScreenInfoButtonsContainer,
  ScreenButtonLink,
} from '~/src/components/forFeatures/styles/styled-components';
import { ReactComponent as Soc2Icon } from '~/src/components/forSolutions/Advantages/assets/soc2.svg';
import { ReactComponent as GrprIcon } from '~/src/components/forSolutions/Advantages/assets/gdpr.svg';
import {
  SafeConversionScreenGradientSuperHeading,
  SafeConversionFeatureContainer,
  SafeConversionScreenInfoImageContainer,
  SafeConversionFeaturesWrapper,
  SafeConversionScreenIntegrations,
} from './styles/SafeConversionScreen.styles';

export const SafeConversionScreen = () => (
  <Section data-navbar-theme="dark">
    <Container>
      <SafeConversionScreenGradientSuperHeading>
        Safe file conversion
      </SafeConversionScreenGradientSuperHeading>
      <SolutionsSubHeading>Integrate document conversion quickly and securely</SolutionsSubHeading>

      <SafeConversionFeaturesWrapper>
        <SafeConversionFeatureContainer>
          <FeaturesSubtitle>Secure & compliant</FeaturesSubtitle>
          <ScreenDescriptionWrapper>
            <ScreenInfoDescription>
              Trust in Uploadcare’s commitment to security and privacy. We’re GDPR and HIPAA
              compliant and provide SSL encryption for all data transfers.
            </ScreenInfoDescription>
          </ScreenDescriptionWrapper>
          <SafeConversionScreenInfoImageContainer>
            <GrprIcon />
            <Soc2Icon />
          </SafeConversionScreenInfoImageContainer>
        </SafeConversionFeatureContainer>

        <SafeConversionFeatureContainer>
          <FeaturesSubtitle>Stack-agnostic API</FeaturesSubtitle>
          <ScreenDescriptionWrapper>
            <ScreenInfoDescription>
              Our API is designed for developers, offering easy integration, comprehensive
              documentation, and high-level libraries for your favorite languages.
            </ScreenInfoDescription>
            <ScreenInfoButtonsContainer>
              <FeaturesButton
                forwardedAs={ScreenButtonLink}
                to="/docs/integrations/"
                data-analytics="btn_safe-conversion_integrations"
                type={ButtonTypeEnum.TRANSPARENT}
                size={ButtonSizeEnum.EXTRA_SMALL}
                ghost
              >
                See integrations
              </FeaturesButton>
            </ScreenInfoButtonsContainer>
          </ScreenDescriptionWrapper>
        </SafeConversionFeatureContainer>
      </SafeConversionFeaturesWrapper>
    </Container>

    <SafeConversionScreenIntegrations />
  </Section>
);
