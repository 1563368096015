import React from 'react';

import { ButtonTypeEnum } from '@uc-common/button';

import { Clients } from '~/src/components/Clients';
import { Link } from '../../Link';
import {
  Heading,
  StyledContainer,
  StyledSection,
  StyledSubText,
  FirstScreenButton,
  FirstScreenGradientSuperHeading,
} from './styles/FirstScreen.styles';

export const FirstScreen = ({ btnSignUpProps = {} }) => (
  <StyledSection>
    <StyledContainer>
      <FirstScreenGradientSuperHeading>Document conversion</FirstScreenGradientSuperHeading>
      <Heading>Document & File Conversion API</Heading>

      <StyledSubText>
        Convert between documents, data, PDF, images and more with as little HTTP&nbsp;requests as
        possible.
      </StyledSubText>

      <FirstScreenButton
        forwardedAs={Link}
        to="/accounts/signup/"
        type={ButtonTypeEnum.PRIMARY}
        data-analytics="btn_signup_0"
        {...btnSignUpProps}
      >
        Start Building for Free
      </FirstScreenButton>

      <Clients />
    </StyledContainer>
  </StyledSection>
);
