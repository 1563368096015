import React from 'react';

import { SiteLayout } from '../../components/Layout/SiteLayout';
import {
  FirstScreen,
  ServicesScreen,
  UseCasesScreen,
  FormatsScreen,
  ConversionFeaturesScreen,
  SafeConversionScreen,
  LastScreen,
} from '../../components/forDocumentConversion';

export const frontmatter = {
  title: 'Document Conversion',
  description:
    'Convert between documents, data, PDF, images and more with as little HTTP requests as possible.',
};

const ELearning = ({ location }) => (
  <SiteLayout meta={{ ...frontmatter }} pathName={location.pathname} isDark>
    <FirstScreen />
    <ServicesScreen />
    <UseCasesScreen />
    <FormatsScreen />
    <ConversionFeaturesScreen />
    <SafeConversionScreen />
    <LastScreen />
  </SiteLayout>
);

export default ELearning;
