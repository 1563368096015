import React from 'react';

import { ConversionFeaturesScreenInlineLink } from './styles/ConversionFeaturesScreen.styles';

export const data = [
  {
    title: 'All document file extensions',
    text: 'Access and convert 1100+ popular doc types: office suites, rich text, data, images, design/CAD and more.',
  },
  {
    title: 'Preview docs as images',
    text: 'Create document thumbnails or image previews. Specify output format, quality, size, formatting.',
  },
  {
    title: 'Multi-page documents',
    text: (
      <>
        Preview multi-page documents as images, accessible as .zip, downloadable files, or{' '}
        <ConversionFeaturesScreenInlineLink to="/cdn/">CDN</ConversionFeaturesScreenInlineLink>{' '}
        links.
      </>
    ),
  },
  {
    title: 'Legacy document conversion',
    text: 'If your software supports only legacy file formats, automate the conversion without manual work.',
  },
  {
    title: 'Excel to CSV Conversion',
    text: 'Convert your Excel files (XLS or XLSX) to CSV for efficient data parsing and database integration.',
  },
  {
    title: 'Add watermarks',
    text: (
      <>
        Add{' '}
        <ConversionFeaturesScreenInlineLink to="/docs/transformations/image/overlay/">
          overlays
        </ConversionFeaturesScreenInlineLink>{' '}
        and watermarks to your document image previews. Perfect for UGC documents.{' '}
      </>
    ),
  },
];
