import React from 'react';
import { LinkWithArrow } from '~/src/components/Typography';
import {
  ServicesHeading,
  ServicesReuploadingText,
  ServicesReuploadingSmall,
  ServicesReuploadingSmaller,
} from './styles/ServicesScreen.styles';

export const SERVICES = [
  {
    heading: 'One API for all your document conversions',
    paragraph:
      'Process and convert all popular document file formats in one place. Generate preview images, convert Word to PDF, handle user-generated documents at scale, and more.',
    imageUuid: '913d9e18-8be9-44db-97ea-47f7dc64ab9d',
    imageAlt: 'One API for all your document conversions',
  },
  {
    heading: (
      <ServicesHeading>
        Convert files without
        <ServicesReuploadingText>
          <ServicesReuploadingSmaller>re</ServicesReuploadingSmaller>
          <ServicesReuploadingSmall>re</ServicesReuploadingSmall>reuploading
        </ServicesReuploadingText>
      </ServicesHeading>
    ),
    paragraph: (
      <>
        More results with less manual work. When a document is converted we upload it automatically
        as a new file with a unique identifier bound to the original, ready for retrieval and
        further processing.
        <br />
        <br />
        <LinkWithArrow
          to="/docs/transformations/document-conversion/"
          data-analytics="btn_services_document-conversion-docs"
        >
          Document conversion docs
        </LinkWithArrow>
      </>
    ),
    imageUuid: '2a6218bd-75f6-4190-8612-a436c6a7d45c',
    imageOptions: { preview: '777x532', quality: 'smart', format: 'auto' },
    imageAlt: 'Convert files without reuploading',
    revert: true,
  },
];
