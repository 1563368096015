import React from 'react';

import { Container } from '@uc-common/container';

import { LinkWithArrow } from '~/src/components/Typography';
import { SolutionsSubHeading } from '~/src/components/forSolutions/styled-components';
import { SmallCard } from '~/src/components/forSolutions';
import { Section } from '../../forMain/Section';
import { UseCasesScreenCardsContainer } from './styles/UseCasesScreen.styles';
import { FeaturesScreenGradientSuperHeading } from '../../forELearning/FeaturesScreen/styles/FeaturesScreen.styles';

export const UseCasesScreen = () => (
  <Section data-navbar-theme="dark">
    <Container>
      <FeaturesScreenGradientSuperHeading>Use cases</FeaturesScreenGradientSuperHeading>
      <SolutionsSubHeading>
        Convert documents to the file types your users expect
      </SolutionsSubHeading>
      <UseCasesScreenCardsContainer>
        <SmallCard
          title="Generate document preview images"
          src="https://ucarecdn.com/db7ce875-a010-4df0-9ef0-bc178ffad8c0/eye.svg"
          text="Create document thumbnails and image previews, then adjust size, resolution, and add watermarks."
        />
        <SmallCard
          title="Convert Word, Pages & OpenDocument to PDF"
          src="https://ucarecdn.com/8505727e-8f2e-416e-a550-763c9e807d41/pdf.svg"
          text={
            <>
              Transform your rich text files into universally accessible PDFs. Preserve formatting
              or automate it for different devices.
              <br />
              <br />
              <LinkWithArrow
                to="/blog/how-to-convert-docs-to-pdf/"
                data-analytics="btn_use-cases_how-to-convert-docs-to-pdf"
              >
                MS Word to PDF guide
              </LinkWithArrow>
            </>
          }
        />
        <SmallCard
          title="Process UGC files and documents"
          src="https://ucarecdn.com/0e928b99-e406-4f5e-9be4-edb80fe78ee5/upload.svg"
          text="Convert user-generated documents at scale, such as product info documents for your ecommerce platform."
        />
        <SmallCard
          title="Visualize and preview unique doc types"
          src="https://ucarecdn.com/40b3ac50-d8a5-4673-a42c-35751e9f8eb4/variety.svg"
          text="Turn specialized files into preview-friendly formats: Doc to EPUB, markdown to PDF, LaTeX to HTML, Autocad to JPG & more."
        />
      </UseCasesScreenCardsContainer>
    </Container>
  </Section>
);
