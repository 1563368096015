import styled, { css } from 'styled-components';

export const ServicesContainer = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    display: grid;
    grid-gap: 70px;

    ${tablet()} {
      grid-gap: 100px;
    }

    ${laptop()} {
      grid-gap: 130px;
    }
  `
);

export const ServicesHeading = styled.p`
  max-width: 300px;
`;

export const ServicesReuploadingText = styled.p`
  display: inline-flex;
  color: #ffffff;
  white-space: nowrap;
  align-items: center;
`;

export const ServicesReuploadingSmall = styled(ServicesReuploadingText)(
  ({ theme: { tablet, laptop } }) => css`
    color: #878787;
    font-size: 19px;

    ${tablet()} {
      font-size: 21px;
    }

    ${laptop()} {
      font-size: 24px;
    }
  `
);

export const ServicesReuploadingSmaller = styled(ServicesReuploadingSmall)(
  ({ theme: { tablet, laptop } }) => css`
    color: #5e5e5e;
    font-size: 15px;

    ${tablet()} {
      font-size: 17px;
    }

    ${laptop()} {
      font-size: 20px;
    }
  `
);
