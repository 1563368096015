import styled, { css } from 'styled-components';
import { Button } from '@uc-common/button';
import Image from '@uc-common/image';

import { SectionHeading, BigHeading, LinkWithArrow } from '../../Typography';

export const FeaturesButton = styled(Button)`
  border-radius: 100px;
  margin-right: 10px;
  margin-bottom: 10px;
  color: #8fa3ae;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3;
  padding: 8px 16px;

  &:visited {
    font-weight: 500;
    color: #8fa3ae;
  }
`;

export const FeaturesSectionHeading = styled(SectionHeading)(
  ({ theme: { tablet, laptop, desktop } }) => css`
    font-size: 28px;
    line-height: 1.3;
    text-align: left;
    letter-spacing: -0.025em;
    color: #ffffff;

    ${tablet()} {
      font-size: 32px;
    }

    ${laptop()} {
      font-size: 38px;
    }

    ${desktop()} {
      font-size: 44px;
    }
  `
);

export const FeaturesBigHeading = styled(BigHeading)(
  ({ theme: { tablet, laptop } }) => css`
    font-size: 32px;
    line-height: 1.2;
    text-align: left;
    letter-spacing: -0.025em;
    color: #ffffff;
    margin-bottom: 10px;

    ${tablet()} {
      font-size: 44px;
    }

    ${laptop()} {
      font-size: 54px;
    }
  `
);

export const FeaturesSubtitle = styled.h3(
  ({ theme: { laptop } }) => css`
    font-size: 20px;
    line-height: 1.3;
    text-align: left;
    letter-spacing: -0.015em;
    color: #ffffff;
    margin-bottom: 15px;
    grid-area: subtitle;

    ${laptop()} {
      font-size: 22px;
      width: 185px;
    }
  `
);

export const ScreenHeaderContainer = styled.div(
  ({ theme: { laptop } }) => css`
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #21262f;
    display: flex;
    align-items: center;
    scroll-margin-top: 70px;

    ${laptop()} {
      margin-bottom: 40px;
      scroll-margin-top: 100px;
    }
  `
);

export const ScreenHeaderIcon = styled(Image)(
  ({ theme: { laptop } }) => css`
    width: 58px;
    height: 58px;
    margin-right: 20px;

    ${laptop()} {
      margin-right: 25px;
    }
  `
);

export const ScreenInfoSection = styled.section(
  ({ theme: { tablet, laptop, desktop } }) => css`
    padding-bottom: 25px;
    margin-bottom: 30px;
    border-bottom: 1px solid #21262f;
    display: flex;
    flex-direction: column;
    scroll-margin-top: 70px;

    ${tablet()} {
      padding-bottom: 30px;
      grid-column-gap: 40px;
      grid-template-columns: 19fr 14fr;
      display: grid;
      grid-template-areas: 'subtitle image' 'description image';
    }

    ${laptop()} {
      padding-bottom: 40px;
      margin-bottom: 40px;
      grid-template-columns: 18fr 40fr 20fr;
      grid-template-areas: 'subtitle description image';
      scroll-margin-top: 100px;
    }

    ${desktop()} {
      grid-template-columns: 15fr 40fr 23fr;
      padding-bottom: 40px;
      margin-bottom: 40px;
    }
  `
);

export const ScreenDescriptionWrapper = styled.div(
  ({ theme: { laptop } }) => css`
    display: block;
    grid-area: description;

    ${laptop()} {
      margin-right: 15px;
    }
  `
);

export const ScreenInfoDescription = styled.p`
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: #bbc4d2;
  margin-bottom: 20px;
`;

export const ScreenInfoButtonsContainer = styled.div(
  ({ theme: { tablet } }) => css`
    display: flex;
    flex-wrap: wrap;
    max-width: 70%;

    ${tablet()} {
      max-width: 100%;
    }
  `
);

export const ScreenButtonLink = styled(LinkWithArrow)`
  &:hover {
    color: #ffffff;
  }
`;

export const ScreenInfoImageContainer = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    grid-area: image;
    width: 100%;
    min-height: 150px;
    height: fit-content;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    display: block;

    ${tablet()} {
      max-width: 320px;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: 0px;
      margin-right: 0px;
    }

    ${laptop()} {
      max-width: auto;
    }
  `
);

export const ScreenInfoImage = styled(Image)(
  ({ theme: { tablet } }) => css`
    display: block;
    margin-top: 25px;
    object-fit: cover;
    width: 100%;

    ${tablet()} {
      margin-top: 0px;
    }
  `
);
