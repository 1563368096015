import styled, { css } from 'styled-components';
import { Container } from '@uc-common/container';
import { GradientSuperHeading } from '~/src/components/Typography';
import { SubText, CTAButton } from '../../../forMain/Typography';
import { Section } from '../../../forMain/Section';
import topGradient from '../../assets/topGradient.svg';

export const Heading = styled.h1(
  ({ theme: { tablet, laptop, desktop } }) => css`
    font-size: 30px;
    line-height: 1.2;
    font-weight: 700;
    margin-bottom: 19px;
    color: #ffffff;
    display: inline-flex;
    flex-direction: column;

    ${tablet()} {
      font-size: 56px;
      margin-bottom: 30px;
    }

    ${laptop()} {
      font-size: 60px;
    }

    ${desktop()} {
      font-size: 70px;
      max-width: 910px;
    }
  `
);

export const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const StyledSection = styled(Section)(
  ({ theme: { tablet, desktop } }) => css`
    padding-top: 80px;
    background: url(${topGradient}) no-repeat center;
    background-size: 800px auto;
    background-position-y: 0;

    ${tablet()} {
      padding-top: 120px;
      background-size: auto auto;
    }

    ${desktop()} {
      padding-top: 150px;
      background-size: 1400px auto;
    }
  `
);

export const StyledSubText = styled(SubText)(
  ({ theme: { laptop } }) => css`
    max-width: 640px;

    ${laptop()} {
      max-width: 830px;
    }
  `
);

export const FirstScreenButton = styled(CTAButton)`
  margin-top: 30px;
  max-width: 300px;
  width: 100%;
  font-size: 16px;
`;

export const FirstScreenGradientSuperHeading = styled(GradientSuperHeading)`
  background: linear-gradient(83deg, #a0b61a 21.51%, #67b100 53.81%, #2cd042 88.92%);
`;
