import styled, { css } from 'styled-components';
import { GradientSuperHeading } from '~/src/components/Typography';
import { Integrations } from '~/src/components/forMain/Uploading/Integrations';
import {
  ScreenInfoSection,
  FeaturesSubtitle,
  ScreenInfoImageContainer,
} from '~/src/components/forFeatures/styles/styled-components';

export const SafeConversionScreenGradientSuperHeading = styled(GradientSuperHeading)`
  align-self: center;
  display: block;

  background: radial-gradient(
    141.01% 172.5% at 0% 0%,
    rgb(185, 81, 190) 0%,
    rgb(147, 101, 253) 84.45%
  );
`;

export const SafeConversionFeatureContainer = styled(ScreenInfoSection)(
  ({ theme: { tablet, laptop, desktop } }) => css`
    ${tablet()} {
      grid-template-columns: 4fr 3fr;
    }

    ${laptop()} {
      grid-template-columns: 1fr 2fr 1fr;
    }

    ${desktop()} {
      grid-template-columns: 3fr 8fr 3fr;
    }

    ${FeaturesSubtitle} {
      width: 100%;
    }
  `
);

export const SafeConversionScreenInfoImageContainer = styled(ScreenInfoImageContainer)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  justify-items: center;
`;

export const SafeConversionFeaturesWrapper = styled.div`
  display: grid;
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid #21262f;
`;

export const SafeConversionScreenIntegrations = styled(Integrations)(
  ({ theme: { tablet } }) => css`
    margin-top: 15px;
    padding-bottom: 30px;

    ${tablet()} {
      padding-bottom: 60px;
      margin-top: 35px;
    }
  `
);
