import React from 'react';

import { Container } from '@uc-common/container';
import { BuzzLogoIdEnum, logos } from '@uc-common/buzz-logo';
import { Quote, Service } from '~/src/components/forSolutions';
import { Section } from '~/src/components/forMain/Section';
import { ServicesContainer } from './styles/ServicesScreen.styles';
import { SERVICES } from './data';

export const ServicesScreen = () => (
  <Section data-navbar-theme="dark">
    <Container>
      <ServicesContainer>
        {SERVICES.map(
          (
            {
              superHeading,
              heading,
              paragraph,
              options = [],
              imageUuid,
              imageAlt,
              imageOptions,
              revert,
            },
            index
          ) => (
            <Service
              index={index + 1}
              key={heading}
              superHeading={superHeading}
              heading={heading}
              paragraph={paragraph}
              options={options}
              imageUuid={imageUuid}
              imageAlt={imageAlt}
              imageOptions={imageOptions}
              revert={revert}
            />
          )
        )}
      </ServicesContainer>
    </Container>

    <Quote
      logoSrc={logos[BuzzLogoIdEnum.MOZILLA].src}
      logoAlt="Mozilla logo"
      quoteText="It's the most painless, enjoyable flow out there. Awesome product. We all love Uploadcare dearly."
      avatarSrc="https://ucarecdn.com/6bf9903e-584d-459c-b621-819a5bf4910a/-/scale_crop/88x88/center/-/format/auto/-/quality/normal/"
      avatarAlt="Chris Van Wiemeersch | UX Engineer"
    />
  </Section>
);
