import styled, { css } from 'styled-components';

import { Button } from '@uc-common/button';
import { SolutionsSubHeading } from '~/src/components/forSolutions/styled-components';
import { CTAButton } from '~/src/components/forMain/Typography';

export const FormatsScreenSubHeading = styled(SolutionsSubHeading)`
  max-width: 650px;
`;

export const FormatsScreenButton = styled(Button)`
  display: block;
  margin-top: 0;
  padding: 0 40px;
  font-size: 16px;
  margin: 30px auto 0;
`;

export const FormatsScreenCTAButton = styled(CTAButton)`
  margin-top: 0;
  padding: 0 40px;
  font-size: 16px;
`;

export const FormatsScreenButtonsContainer = styled.div`
  margin-top: 30px;
`;

export const FormatsScreenAvailableFormatsContainer = styled.div(
  ({ theme: { laptop }, $isExpanded = false }) => css`
    position: relative;
    overflow-y: hidden;
    height: ${$isExpanded ? 'fit-content' : '200px'};
    margin-top: 60px;

    ${laptop()} {
      height: ${$isExpanded ? 'fit-content' : '400px'};
      margin-top: 80px;
    }
  `
);

export const FormatsScreenFormatsGrid = styled.div(
  ({ theme: { tablet, laptop, desktop } }) => css`
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    flex-direction: column;

    ${tablet()} {
      max-height: 2250px;
    }

    ${laptop()} {
      max-height: 2000px;
    }

    ${desktop()} {
      max-height: 2200px;
    }
  `
);

export const FormatsScreenFormatSectionTitle = styled.h4(
  ({ theme: { laptop } }) => css`
    font-weight: 700;
    text-transform: uppercase;
    font-size: 13px;
    color: #ffffff;

    ${laptop()} {
      font-size: 15px;
    }
  `
);

export const FormatsScreenFormatSectionList = styled.div`
  display: grid;
  grid-gap: 5px;
  margin-top: 10px;
`;

export const FormatsScreenFormatSectionListItem = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 55px auto;
`;

export const FormatsScreenFormatSectionListItemName = styled.span(
  ({ theme: { laptop }, $color }) => css`
    font-weight: 400;
    font-family: var(--font-mono);
    font-size: 12px;
    letter-spacing: 0.004px;
    color: ${$color};

    ${laptop()} {
      font-size: 14px;
    }
  `
);

export const FormatsScreenFormatSectionListItemDescription = styled.p(
  ({ theme: { laptop, desktop } }) => css`
    font-size: 12px;
    color: #62707b;
    max-width: 300px;

    ${laptop()} {
      max-width: 240px;
      font-size: 14px;
    }

    ${desktop()} {
      max-width: 300px;
    }
  `
);

export const FormatsScreenGradientOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 200px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
`;
